<template>
    <div class="footer">
        <div class="footer-inner">
        <!--<div class="Qrcode">
            <img :src='img1'>
            官方服务号
        </div>-->
        <section style="padding-right: 100px;">
            <div class="item">
                <div class="item-name">重庆市交通局：</div>
                <div class='item-value'>
                    <ul>
                        <li><a href="http://jtj.cq.gov.cn/">首页</a></li>
                        <li><a href="http://jtj.cq.gov.cn/zwgk_240/">政府信息公开</a></li>
                        <li><a href="http://jtj.cq.gov.cn/ggbf_search/lxwm/202004/t20200408_7024440.html">联系我们</a></li>
                    </ul>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </section>
        <!--<section style="padding-right: 100px;">
            <div class="item">
                <div class="item-name">地址：</div>
                <div class='item-value'>
                    上海市虹口区广粤支路87号
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="item">
                <div class="item-name">电话：</div>
                <div class='item-value'>
                    021-56556312
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="item">
                <div class="item-name">传真：</div>
                <div class='item-value'>
                    021-56551276
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="item">
                <div class="item-name">邮编：</div>
                <div class='item-value'>
                    200434
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="item">
                <div class="item-name">邮箱：</div>
                <div class='item-value'>
                    srcc@chinasrcc.com
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </section>-->
        <section>
            <div class="item-name">友情链接：</div>
            <div class='item-value'>
                <ul>
                    <li><a href="https://www.cqmetro.cn/index.shtml">重庆市轨道交通(集团)有限公司</a></li>
                    <li><a href="http://samr.saic.gov.cn/">国家质检总局</a></li>
                    <li><a href="http://www.cnca.gov.cn/">中国国家认证认可监督管理委员会</a></li>
                    <li><a href="https://www.cnas.org.cn/">中国合格评定国家认可委员会</a></li>
                    <li><a href="http://www.ccaa.org.cn/">中国认证认可协会</a></li>
                    <li><a href="http://www.chinasrcc.com/">上海轨道交通检测认证（集团）有限公司</a></li>
                    <li><a href="https://www.tongji.edu.cn/">同济大学</a></li>
                </ul>
            </div>
            <div class="clearfix"></div>
        </section>
        <section class='copyright'>
            <div>Copyright Centre Testing International All rights resereved 沪ICP备19045397号-2</div>
            <div><img :src="img2"><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402008424" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;position:relative;top:5px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">沪公网安备 31011402008424号</p></a></div>
        </section>
    </div>
    </div>
    <!--<div class="innerbox">
            <div class="content">
                <dl>
                    <dt>公司介绍</dt>
                    <dd><a href="http://www.chinasrcc.com/1_3.html">发展历程</a></dd>
                    <dd><a href="http://www.chinasrcc.com/1_21.html">资质荣誉</a></dd>
                    <dd><a href="http://www.chinasrcc.com/7_1.html">联系我们</a></dd>
                </dl>
                <dl>
                    <dt>友情链接</dt>
                    <dd><a href="http://www.chinasrcc.com/">公司主页</a></dd>
                    <dd><a href="http://samr.saic.gov.cn/">国家质检总局</a></dd>
                </dl>
                <dl>
                    <dt>委托及报告</dt>
                    <dd>报告查询</dd>
                </dl>
                <div class="address">
                    <p class="footer-phone">电话：021-56556312</p>
                    <p class="footer-phone">传真：021-56551276</p>
                    <p>地址：上海市虹口区广粤支路87号</p>
                    <p>邮编：200434</p>
                    <p>邮箱：srcc@chinasrcc.com</p>
                </div>
            </div>
            <div class="buttom-remark">
                <div class="b-r-p"><span>Copyright Centre Testing International All rights reserved 沪ICP备16013901号-2</span></div>
                <div class="b-r-p"><span><img  class="b-r-img" src="../assets/gaicon.png"></span><span>沪公网安备 44038882000441号</span></div>
            </div>
        </div>-->

</template>

<script>
    export default {
        name: "footerBar",
        data() {
            return {
                img1: process.env.BASE_URL + "img/qrcode.png",
                img2: process.env.BASE_URL + "img/Bitmap.png",

            }
        },
        props: {

        },
        methods: {

        }
    }
</script>

<style scoped>
    body,ul,ol,li,p,h1,h2,h3,h4,h5,h6,form,fieldset,table,td,img,div,dl,dt,dd,input{
    margin:0;padding:0;border:0;font-family:"Microsoft YaHei";font-size: 12px;
}
.pull-left{
    float:left;
}
.pull-right{
    float:right;
}
.clearfix{
    clear: both;
}
ul>li{
    list-style: none;
}
a{
    text-decoration: none;
    color: #000000;
	
}
img{
    max-width: 100%;
}
.content{
    padding:30px 80px;
}
    a {
        color: inherit;
    }

    a:link {
        color: inherit;

    }

    a:visited {
        color: inherit;
    }

    a:hover {
        color: inherit;
    }

    a:active {
        color: inherit;
    }

    .footer {
        background: rgb(34, 34, 54);
        padding: 30px 80px 16px;
        position: relative;
        width:100%;
        min-width: 1260px;
        z-index:129;
    }

    .footer-inner{
        width:1260px;
        margin:0 auto;
        position: relative;
    }

    .item {
        float: left;
        padding-right: 15px;
        line-height: 30px;
    }

    .item-name {
        color: #FFFFFF;
        letter-spacing: 1px;
        float: left;
        line-height: 30px;
    }

    .item-value {
        color: #C4C4C4;
        float: left;
        line-height: 30px;
    }

    .item-value li {
        padding-right: 10px;
    }

    ul li {
        list-style: none;
        display: inline-block;
    }

    .copyright {
        margin-top: 15px;
        padding-top: 10px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        font-size: 12px;
        text-align: center;
        color: #FFFFFF;
        letter-spacing: 0.86px;
        line-height: 27px;
    }

    .copyright img {
        position: relative;
        top: 5px;
        left: -8px;
    }

    .Qrcode {
        position: absolute;
        right: 80px;
        color: #C4C4C4;
        width: 70px;
        text-align: center;
    }
</style>